<template>
  <div>
    <b-overlay :show="showAgreement" no-center>
      <b-row>
        <b-col md="1" />
        <b-col md="10" class="mb-2">
          <b-card border-variant="light">
            <form-wizard color="#7367F0" title="" :subtitle="null" shape="circle" class="mb-3" ref="mainForm" errorColor="red">
              <tab-content title="Supported Programs" icon="feather icon-heart">
                <b-card>
                  <b-card-title>
                    <template>
                      <h1>Supported Programs</h1>
                    </template>
                  </b-card-title>

                  <b-card-text> Sweetleaf Patient Collective currently supports the following programs: </b-card-text>
                  <b-card-group deck>
                    <b-card max-width="100px" border-variant="info">
                      <b-img fluid :src="sweetleaf" alt="Sweetleaf Collective" />
                      <b-card-text> The SweetLeaf Patient Collective </b-card-text>
                      <b-card-text> <b>Qualifying requirements:</b> Low Income</b-card-text>
                    </b-card>
                    <b-card max-width="100px" border-variant="info">
                      <b-img fluid :src="evac" alt="Operation EVAC" />
                      <b-card-text> Operation EVAC (Educating Veterans About Cannabis) </b-card-text>
                      <b-card-text> <b>Qualifying requirements:</b> Veteran </b-card-text>
                    </b-card>
                    <b-card max-width="100px" border-variant="info">
                      <b-img fluid :src="jazz" alt="Jazz Cabbage" />
                      <b-card-text> Jazz Cabbage Oak </b-card-text>
                      <b-card-text> <b>Qualifying requirements:</b> BIPOC </b-card-text>
                    </b-card>
                    <b-card max-width="100px" border-variant="info">
                      <b-img fluid :src="cornerstone" alt="Corner Stone" />
                      <b-card-text> Cornerstone Wellness </b-card-text>
                      <b-card-text> <b>Qualifying requirements:</b> General </b-card-text>
                    </b-card>
                  </b-card-group>
                </b-card>
              </tab-content>

              <tab-content title="Qualification Check" icon="feather icon-user-check" :before-change="validateQualifications">
                <b-form-group
                  id="input-group-name"
                  label-cols="2"
                  label-align="right"
                  content-cols="6"
                  invalid-feedback="Enter patient's full name here (required)"
                  :state="!(!fullname || fullname.length === 0)"
                >
                  <template #label>
                    <b> Name: </b>
                  </template>
                  <b-form-input id="input-name" maxlength="250" v-model="fullname" type="search" required></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-email"
                  label-cols="2"
                  content-cols="6"
                  label-align="right"
                  invalid-feedback="Enter patient's email/phone if available (phone or email required)"
                  :state="!((!email || email.length === 0) && (!phone || phone.length === 0))"
                >
                  <template #label>
                    <b>Contact: </b>
                  </template>
                  <b-form-row>
                    <b-col role="group">
                      <b-form-input id="input-email" maxlength="120" v-model="email" type="email" placeholder="Enter email address" required></b-form-input>
                    </b-col>
                    <b-col role="group">
                      <b-form-input id="input-group-phone" maxlength="25" v-model="phone" placeholder="Enter phone number" required></b-form-input>
                    </b-col>
                  </b-form-row>
                </b-form-group>

                <b-form-group id="input-group-bday" label-cols="2" content-cols="6" label-align="right" invalid-feedback="Enter patient's birth date" :state="!!birthday">
                  <template #label>
                    <b>Birthday: </b>
                  </template>
                  <b-form-row>
                    <b-col role="group">
                      <b-form-input id="input-bday" v-model="birthday" type="date" required></b-form-input>
                    </b-col>
                  </b-form-row>
                </b-form-group>

                <b-row>
                  <b-col md="6" class="mt-2">
                    <b-form-group id="input-group-demographics" label-cols="2" label-align="right">
                      <b-card title="Demographics" sub-title="">
                        <b-col md="6">
                          <b-form-checkbox-group id="checkbox-group-demographics" v-model="demographics" name="demographics" stacked>
                            <b-form-checkbox value="lowIncome">Low Income</b-form-checkbox>
                            <b-form-checkbox value="bipoc">BIPOC</b-form-checkbox>
                            <b-form-checkbox value="lgbtq">LGBTQA+</b-form-checkbox>
                            <b-form-checkbox value="veteran">Veteran</b-form-checkbox>
                            <b-form-checkbox value="general">General</b-form-checkbox>
                          </b-form-checkbox-group>
                        </b-col>
                      </b-card>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="mt-2">
                    <b-form-group id="input-group-medical" label-cols="2" label-align="right">
                      <b-card title="Medical" sub-title="">
                        <b-form-checkbox-group id="checkbox-group-medical" v-model="medical" name="demographics" stacked>
                          <b-form-checkbox value="hiv">Hiv / Aids / Cancer</b-form-checkbox>
                          <b-form-checkbox value="terminal">Other terminal illness</b-form-checkbox>
                          <b-form-checkbox value="mobility">Mobility Restrictions</b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-card>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="fs-1 mb-2 mt-2" height="100px;">
                  <b-col md="1" />
                  <b-col md="11 fs-1" height="100px;">
                    <b-alert style="font-size: 2rem !important; padding-left: 15px !important" variant="warning" :show="showQualification">
                      {{ programQualifier }}
                    </b-alert>

                    <b-alert style="font-size: 2rem !important; padding-left: 15px !important" variant="danger" :show="!showQualification">
                      Does not qualify for any supported program
                    </b-alert>
                    <b-card v-if="showQualification" border-variant="info" title="Required Documents">
                      <ul>
                        <b-card-text v-for="doc of requiredDocuments" :key="doc.type">
                          <li>{{ doc.description }} <span v-if="doc.bothSides">(Both sides)</span></li>
                        </b-card-text>
                      </ul>
                    </b-card>
                    <b-card v-if="showQualification && optionalDocuments && optionalDocuments.length > 0" border-variant="warning" title="Good to have">
                      <ul>
                        <b-card-text v-for="doc of optionalDocuments" :key="doc.type">
                          <li>{{ doc.description }} <span v-if="doc.bothSides">(Both sides)</span></li>
                        </b-card-text>
                      </ul>
                    </b-card>
                  </b-col>
                </b-row>
              </tab-content>

              <tab-content title="Delivery Address" icon="feather icon-home" :before-change="validateAddress">
                <validation-observer ref="checkAddress" tag="form">
                  <b-row>
                    <b-col md="11">
                      <b-card title="Mailing / Delivery Address">
                        <b-card-text> Please update the following information: </b-card-text>
                        <b-card border-variant="info" title="Address">
                          <b-card-text>
                            <b-form-group id="input-group-1" label-cols="2" label-align="right">
                              <template #label>
                                <b>Street:</b>
                              </template>
                              <validation-provider #default="{ errors }" name="Street Address" rules="required" mode="eager">
                                <b-form-input id="input-streetAddress" maxlength="200" v-model="address.street" required></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-card-text>
                          <b-card-text>
                            <b-form-group id="input-group-2" label-cols="2" label-align="right">
                              <template #label>
                                <b>City:</b>
                              </template>
                              <validation-provider #default="{ errors }" name="City" rules="required" mode="eager">
                                <b-form-input id="input-city" maxlength="100" v-model="address.city" required></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-card-text>
                          <b-card-text>
                            <b-form-group id="input-group-3" label-cols="2" label-align="right">
                              <template #label>
                                <b>State:</b>
                              </template>
                              <validation-provider #default="{ errors }" name="State" rules="required" mode="eager">
                                <b-form-input id="input-state" maxlength="2" v-model="address.state" required></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-card-text>
                          <b-card-text>
                            <b-form-group id="input-group-4" label-cols="2" label-align="right">
                              <template #label>
                                <b>Zip:</b>
                              </template>
                              <validation-provider #default="{ errors }" name="Zip" rules="required" mode="eager">
                                <b-form-input id="input-zip" maxlength="10" v-model="address.zip" required></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-card-text>
                          <b-card-text>
                            <b-form-group id="input-group-5" label-cols="4" label-align="right">
                              <template #label>
                                <b>Reliable for delivery?</b>
                              </template>

                              <b-form-checkbox switch v-model="address.reliable" />
                            </b-form-group>
                          </b-card-text>
                        </b-card>
                      </b-card>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>

              <tab-content title="Supporting Data" icon="feather icon-file-text" :before-change="validateDocuments">
                <b-row>
                  <b-col md="11">
                    <b-card>
                      <b-card-title>
                        <template>
                          <h1>Program Documents</h1>
                        </template>
                      </b-card-title>

                      <b-card border-variant="info">
                        <template v-if="evacQualified">
                          <b-card-text> Operation EVAC requires the following documents to validate patient eligibility </b-card-text>
                        </template>
                        <template v-if="jazzCabbageQualified">
                          <b-card-text> Jazz Cabbage Oak requires the following documents to validate patient eligibility</b-card-text>
                        </template>
                        <template v-if="sweetleafQualified">
                          <b-card-text> SweetLeaf Veteran and Patient Collective requires the following documents to validate patient eligibility</b-card-text>
                        </template>
                        <b-card-text v-for="(doc, index) of selectedRequired" :key="doc.id">
                          <b-form-group :id="'input-group-' + index" label-for="'input-group-file-' + index" label-cols="3" label-align="right">
                            <template #label>
                              <b>{{ doc.fileType }}</b>
                            </template>
                            <b-form-group
                              :id="'input-group-doc-details-' + doc.id + '-' + index"
                              invalid-feedback="Document information is required"
                              :state="!((!doc.requiresNumber || doc.requiresNumber.length === 0) && (!doc.requiresExpiration || doc.requiresExpiration.length === 0))"
                              v-if="doc.requiresNumber || doc.requiresExpiration"
                            >
                              <b-form-row v-if="doc.requiresNumber || doc.requiresExpiration || doc.requiresName">
                                <b-col role="group">
                                  <b-form-input
                                    :id="'input-number-' + doc.id + '-' + index"
                                    maxlength="30"
                                    v-model="doc.documentNumber"
                                    type="search"
                                    :placeholder="doc.numberText"
                                    v-if="doc.requiresNumber"
                                  ></b-form-input>
                                </b-col>
                                <b-col role="group" v-if="doc.requiresName">
                                  <b-form-input
                                    :id="'input-name-' + doc.id + '-' + index"
                                    maxlength="30"
                                    v-model="doc.physicianName"
                                    type="search"
                                    :placeholder="doc.nameText"
                                    v-if="doc.requiresName"
                                  ></b-form-input>
                                </b-col>
                                <b-col role="group" v-if="doc.requiresExpiration">
                                  <b-form-datepicker
                                    :id="'input-group-expiration-' + doc.id + '-' + index"
                                    v-model="doc.documentExpiration"
                                    v-if="doc.requiresExpiration"
                                    :placeholder="doc.dateText"
                                    :min="getMinExpiration"
                                  ></b-form-datepicker>
                                </b-col>
                                <b-col role="group" v-if="doc.requiresDocumentDate">
                                  <b-form-datepicker
                                    :id="'input-group-document-date-' + doc.id + '-' + index"
                                    v-model="doc.documentDate"
                                    v-if="doc.requiresDocumentDate"
                                    :placeholder="doc.dateText"
                                    :max="getMaxDate"
                                  ></b-form-datepicker>
                                </b-col>
                              </b-form-row>
                            </b-form-group>
                            <b-form-file
                              :id="'input-group-file-' + index"
                              v-model="doc.file"
                              accept="image/*, application/pdf"
                              :state="Boolean(doc.file)"
                              @input="fileSelectedChange(doc, $event)"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                            <small class="text-danger" :key="filechanged">{{ !doc.file ? doc.description + " is required" : "" }}</small>
                          </b-form-group>
                          <template v-if="doc.breakAfter">
                            <br />
                            <b-card-text />
                          </template>
                        </b-card-text>
                        <br />
                        <b-card-title>
                          <template>
                            <h3>Please provide the following documents if available</h3>
                          </template>
                        </b-card-title>
                        <b-card-text v-for="(doc, index) of selectedOptional" :key="doc.id">
                          <b-form-group :id="'input-group-optional' + index" label-for="'input-group-optional-file-' + index" label-cols="3" label-align="right">
                            <template #label>
                              <b>{{ doc.fileType }}</b>
                            </template>
                            <b-form-group
                              :id="'input-group-opt-details-' + doc.id + '-' + index"
                              invalid-feedback="Document information is optional"
                              :state="!((!doc.requiresNumber || doc.requiresNumber.length === 0) && (!doc.requiresExpiration || doc.requiresExpiration.length === 0))"
                              v-if="doc.requiresNumber || doc.requiresExpiration"
                            >
                              <b-form-row v-if="doc.requiresNumber || doc.requiresExpiration || doc.requiresName">
                                <b-col role="group">
                                  <b-form-input
                                    :id="'input-number-' + doc.id + '-' + index"
                                    maxlength="30"
                                    v-model="doc.documentNumber"
                                    type="search"
                                    :placeholder="doc.numberText"
                                    v-if="doc.requiresNumber"
                                  ></b-form-input>
                                </b-col>
                                <b-col role="group" v-if="doc.requiresName">
                                  <b-form-input
                                    :id="'input-name-' + doc.id + '-' + index"
                                    maxlength="30"
                                    v-model="doc.physicianName"
                                    type="search"
                                    :placeholder="doc.nameText"
                                    v-if="doc.requiresName"
                                  ></b-form-input>
                                </b-col>
                                <b-col role="group" v-if="doc.requiresExpiration">
                                  <b-form-datepicker
                                    :id="'input-group-expiration-' + doc.id + '-' + index"
                                    v-model="doc.documentExpiration"
                                    v-if="doc.requiresExpiration"
                                    :placeholder="doc.dateText"
                                    :min="getMinExpiration"
                                  ></b-form-datepicker>
                                </b-col>
                                <b-col role="group" v-if="doc.requiresDocumentDate">
                                  <b-form-datepicker
                                    :id="'input-group-document-date-' + doc.id + '-' + index"
                                    v-model="doc.documentDate"
                                    v-if="doc.requiresDocumentDate"
                                    :placeholder="doc.dateText"
                                    :max="getMaxDate"
                                  ></b-form-datepicker>
                                </b-col>
                              </b-form-row>
                            </b-form-group>

                            <b-form-file
                              :id="'input-group-optional-file-' + index"
                              v-model="doc.file"
                              accept="image/*, application/pdf"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                            ></b-form-file>
                          </b-form-group>
                          <br />
                        </b-card-text>
                      </b-card>
                    </b-card>
                  </b-col>
                </b-row>
              </tab-content>

              <tab-content title="Releases" icon="feather icon-camera" :before-change="validateReleases">
                <b-row>
                  <b-card title="Patient Agreements">
                    <b-card-text> Please view and sign the following: </b-card-text>
                    <b-card-group deck>
                      <b-card max-width="100px" title="Acknowledgement" :border-variant="patientAckSigned ? 'info' : 'danger'">
                        <b-card-text>
                          <template v-if="patientAckSigned">
                            <small class="success">I have <a @click="() => (showPatientAck = true)" class="text-primary">read and signed the patient acknowledement</a></small>
                          </template>
                          <template v-else>
                            <small class="danger">Please <a @click="() => (showPatientAck = true)" class="text-primary">read and sign the patient acknowledement</a>.</small>
                          </template>
                        </b-card-text>
                        <br />
                        <small style="color: red !important" v-if="!patientAckSigned">Patient Agreement Signature is required</small>
                      </b-card>
                      <b-card max-width="100px" title="Medical Records" :border-variant="patientMedicalSigned ? 'info' : 'danger'">
                        <b-card-text>
                          <template v-if="patientMedicalSigned">
                            <small>I have <a @click="() => (showMedicalRelease = true)" class="text-primary">read and signed the Medical Records Release agreement</a></small>
                          </template>
                          <template v-else>
                            <small>Please <a @click="() => (showMedicalRelease = true)" class="text-primary">read and sign the Medical Records Release agreement</a></small>
                          </template>
                        </b-card-text>
                        <small style="color: red !important" v-if="!patientMedicalSigned">Medical Records Release Agreement Signature is required</small>
                      </b-card>
                      <b-card max-width="100px" title="Media Release" :border-variant="patientMediaSigned ? 'info' : 'warning'">
                        <b-card-text>
                          <template v-if="patientMediaSigned">
                            <small>I have <a @click="() => (showMediaRelease = true)" class="text-primary">read and signed the (optional) media release agreement</a></small>
                          </template>
                          <template v-else>
                            <small>Please <a @click="() => (showMediaRelease = true)" class="text-primary">read and sign the (optional) media release agreement</a>.</small>
                          </template>
                          <b-card-text>
                            <b-form-checkbox v-model="releaseOptOut" class="mt-1">By selecting this checkbox, patient opts out of media releases.</b-form-checkbox>
                          </b-card-text>
                        </b-card-text>
                      </b-card>
                    </b-card-group>
                  </b-card>
                </b-row>
              </tab-content>

              <tab-content title="Submission" icon="feather icon-check-square">
                <b-row>
                  <b-card title="Saving Data">
                    <b-card-text> Thank you for entering data. We will now upload the information to the Sweet Leaf Veteran and Patient Network. </b-card-text>
                    <b-card-group deck>
                      <b-card max-width="200px" title="Patient Information" border-variant="info">
                        <b-card-text>
                          Uploading qualifications <b-spinner v-if="qualUpload.progress" variant="primary" small :key="uploading" /><feather-icon
                            icon="CheckCircleIcon"
                            v-if="!qualUpload.progress && qualUpload.success"
                            class="text-success mr-1"
                          /><feather-icon icon="XCircleIcon" v-if="!qualUpload.progress && qualUpload.failure" class="text-danger mr-1" />
                        </b-card-text>

                        <b-card-text>
                          Uploading address <b-spinner v-if="addressUpload.progress" variant="primary" small :key="uploading" /><feather-icon
                            icon="CheckCircleIcon"
                            v-if="!addressUpload.progress && addressUpload.success"
                            class="text-success mr-1"
                          /><feather-icon icon="XCircleIcon" v-if="!addressUpload.progress && addressUpload.failure" class="text-danger mr-1" />
                        </b-card-text>

                        <b-card-text>
                          Patient Contact <b-spinner v-if="contactUpload.progress" variant="primary" small :key="uploading" /><feather-icon
                            icon="CheckCircleIcon"
                            v-if="!contactUpload.progress && contactUpload.success"
                            class="text-success mr-1"
                          /><feather-icon icon="XCircleIcon" v-if="!contactUpload.progress && contactUpload.failure" class="text-danger mr-1" />
                        </b-card-text>

                        <b-card-text>
                          Releases <b-spinner v-if="releaseUpload.progress" variant="primary" small :key="uploading" /><feather-icon
                            icon="CheckCircleIcon"
                            v-if="!releaseUpload.progress && releaseUpload.success"
                            class="text-success mr-1"
                          /><feather-icon icon="XCircleIcon" v-if="!releaseUpload.progress && releaseUpload.failure" class="text-danger mr-1" />
                        </b-card-text>
                      </b-card>
                      <b-card max-width="200px" title="Supporting Documents" border-variant="info">
                        <b-card-text> Required Documents </b-card-text>
                        <b-card-text v-for="(doc, index) of selectedRequired" :key="doc.id">
                          <b-card-text>
                            {{ index + 1 }} <b>{{ doc.fileType }}</b> <b-spinner v-if="doc.progress" variant="primary" small :key="uploading" /><feather-icon
                              icon="CheckCircleIcon"
                              v-if="!doc.progress && doc.success"
                              class="text-success mr-1"
                            /><feather-icon icon="XCircleIcon" v-if="!doc.progress && doc.failure" class="text-danger mr-1" />
                          </b-card-text>
                        </b-card-text>
                        <b-card-text></b-card-text>
                        <b-card-text> Nice To Have Documents </b-card-text>
                        <b-card-text v-for="(doc, index) of selectedOptional" :key="doc.id">
                          <template v-if="doc.file">
                            <b-card-text>
                              {{ index + 1 }} <b>{{ doc.fileType }}</b> <b-spinner v-if="doc.progress" variant="primary" small :key="uploading" lang="" />
                              <feather-icon icon="CheckCircleIcon" v-if="!doc.progress && doc.success" class="text-success mr-1" />

                              <feather-icon icon="XCircleIcon" v-if="!doc.progress && doc.failure" class="text-danger mr-1" />
                              <feather-icon icon="CloudOffIcon" v-if="!doc.progress && doc.notAdded" class="text-danger mr-1" />
                            </b-card-text>
                          </template>
                        </b-card-text>
                      </b-card>
                    </b-card-group>
                  </b-card>
                </b-row>
              </tab-content>

              <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                  <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button>
                </div>
                <div class="wizard-footer-right">
                  <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">Next</wizard-button>

                  <wizard-button v-if="stopSave && props.isLastStep" @click.native="resetForm" class="wizard-footer-right" :style="props.fillButtonStyle"> Reset</wizard-button>

                  <wizard-button
                    v-if="props.isLastStep"
                    @click.native="formSubmitted"
                    class="wizard-footer-right finish-button mr-3"
                    :style="props.fillButtonStyle"
                    :disabled="stopSave"
                  >
                    {{ props.isLastStep ? "Save" : "Next" }}</wizard-button
                  >
                </div>
              </template>
            </form-wizard>
          </b-card>
        </b-col>
      </b-row>
      <template #overlay>
        <b-row>
          <b-col md="1" />
          <b-col md="10">
            <b-card title="Patient Acknowledgement" v-if="showPatientAck" border-variant="info">
              <b-card-text>
                In providing its services to the Bay Area community, The Sweetleaf Collective is committed to accommodating low-income, terminally ill individuals without unlawful
                discrimination or harassment based on age, race, color, religion, sex, national origin, marital status, disability, citizenship, sexual orientation, gender
                identity, gender expression, or any other characteristic in accordance with our capacity to help. We reserve the right to terminate donated services if the
                Sweetleaf patient is in violation of the described guidelines listed below.
              </b-card-text>
              <b-card-text><b>The Sweetleaf Collective Guidelines:</b></b-card-text>
              <b-card-text
                >I acknowledge that “The Sweetleaf Collective and its donation partners,” are providing a free service to me (the patient) and it is a privilege and I am not
                entitled to receive free compassion donations from Sweetleaf’s partners if guidelines are not respected.</b-card-text
              >
              <b-card-text
                >I acknowledge that I will conduct myself in a respectful manner including and not limited to (communication- via phone, text, email, social media, social events)
                towards any Sweetleaf Collective volunteer, employee, or Sweetleaf partners and understand that failure to do so will result in termination of donation
                services.</b-card-text
              >
              <b-card-text>I understand that it is my responsibility to maintain good communication with the Sweetleaf Collective and Sweetleaf partners.</b-card-text>
              <b-card-text
                >I understand that it is my responsibility to provide the required paperwork/documents to the Sweetleaf Collective for patient onboarding, and before the document
                expiration date that would impair donation services.</b-card-text
              >
              <b-card-text
                >I understand that The Sweetleaf Collective cannot guarantee access to donations services, and that donation availability is dependent on the donor/donation
                quantity during any given time period.</b-card-text
              >
              <b-card-text
                >I understand that The Sweetleaf Collective partners’ donations are provided every 4 months from the last delivery date, only if supplies are
                available.</b-card-text
              >
              <b-card-text
                >By signing this form I agree to The Sweetleaf Collective &amp; Partners guidelines listed above and termination of services if I am in violation of those
                guidelines.</b-card-text
              >
              <b-card-text></b-card-text>
              <br />
              <b-card-text>Enter your name to electronically sign this document:</b-card-text>
              <b-card-text>
                <b-form-group id="input-group-ack-signature-1" label-for="input-patient-ack-sign" label-cols="2" label-align="right">
                  <template #label>
                    <b>Full Name:</b>
                  </template>
                  <b-form-input id="input-patient-ack-sign" maxlength="500" v-model="patientAckSignature" type="search" placeholder="Enter full name" required></b-form-input>
                </b-form-group>
              </b-card-text>
              <b-button class="mt-1 ml-2 justify-content-end" variant="info" @click="() => (showPatientAck = false)"> Close </b-button>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="1" />
          <b-col md="10">
            <b-card title="Medical Record Release" v-if="showMedicalRelease" border-variant="info">
              <b-card-text>
                I, {{ patientAckSignature }}, am a collective member and medical cannabis patient of The Sweetleaf Collective and do hereby provide consent and authorize The
                Sweetleaf Collective to release to a permitted Sweetleaf delivery donation partner and dispensary only the information specifically necessary to verify my Sweetleaf
                Collective membership and current medical cannabis recommendation. This information will be provided to either the permitted donation delivery partner and/or
                dispensary solely for the purpose of verifying my medical cannabis recommendation status and membership in the Sweetleaf Collective and to provide me access to
                either donation delivery services and/or access to a donation dispensary partner.
              </b-card-text>

              <b-card-text
                >I understand that I can revoke my permission to use my medical documents/information through written (email) notification at any time to the email listed
                below.</b-card-text
              >
              <b-card-text>Please send email notification of revocation of medical record release to: <b>sweetleafassistant@gmail.com</b></b-card-text>
              <b-card-text
                >I have been informed and I understand that The Sweetleaf Collective is composed of medical cannabis patients, donation partners, volunteers, and limited staff who
                have organized together for the purpose of collectively providing for their medical cannabis needs in compliance with the California Health &amp; Safety Code
                11362.5 and 11362.775. As well as SB34.

                <br />
                <b-card-text> Enter your name to electronically sign this document: </b-card-text>
                <b-card-text>
                  <b-form-group id="input-group-media-signature-1" label-for="input-patient-media-sign" label-cols="2" label-align="right">
                    <template #label>
                      <b>Full Name:</b>
                    </template>
                    <b-form-input
                      id="input-patient-media-sign"
                      maxlength="500"
                      v-model="medicalRecordReleaseSignature"
                      type="search"
                      placeholder="Enter full name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-card-text>
              </b-card-text>
              <b-button class="mt-1 ml-2 justify-content-end" variant="info" @click="() => (showMedicalRelease = false)"> Close </b-button>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="1" />
          <b-col md="10">
            <b-card title="Media Release" v-if="showMediaRelease" border-variant="warning">
              <b-card-text>
                I, {{ patientAckSignature }}, grant permission to Sweetleaf Collective/ Team Compassion, hereinafter know to use my image (photographs and/or video) for use in
                Media publications including below:
              </b-card-text>
              <b-card-text>
                <b-form-checkbox v-model="mediaReleases.general">General Publications</b-form-checkbox>
              </b-card-text>
              <b-card-text>
                <b-form-checkbox v-model="mediaReleases.social">Social Media Outlets</b-form-checkbox>
              </b-card-text>
              <b-card-text>
                <b-form-checkbox v-model="mediaReleases.newsletters">Newsletters</b-form-checkbox>
              </b-card-text>
              <b-card-text>
                <b-form-checkbox v-model="mediaReleases.magazines">Magazines</b-form-checkbox>
              </b-card-text>
              <b-card-text>
                <b-form-checkbox v-model="mediaReleases.website">Website and/or Affiliates</b-form-checkbox>
              </b-card-text>
              <b-card-text>
                <b-form-checkbox v-model="mediaReleases.promotional">Promotional Campaigns</b-form-checkbox>
              </b-card-text>

              <b-card-text
                >Captured content is intended and use to help support for the nonprofit organization of Sweetleaf Collective and not for profit organization of Team Compassion, as
                well as their affiants. Content is intended for positive reflections and will help the organizations to grow and better support its community.</b-card-text
              >
              <b-card-text
                >I hereby waive any right to inspect or approve the finished photographs and video content that may be used in the conjunction with them now or in the future,
                whether that use is known to me or unknown, and I waive any right to royalties or other compensation arising from or related to the sue of the image.</b-card-text
              >

              <b-card-text>By entering my initials here I am confirming that I am 21 years of age or older and I am competent to contract in my own name. </b-card-text>
              <b-card-text>
                <b-form-group id="input-group-media-inits-1" label-for="input-patient-media-inits" label-cols="2" label-align="right">
                  <template #label>
                    <b>Initials:</b>
                  </template>
                  <b-form-input
                    id="input-patient-media-inits"
                    maxlength="5"
                    style="max-width: 15% !important"
                    v-model="mediaReleases.patientMediaInitials"
                    type="search"
                    placeholder="Initials"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-card-text>
              <b-card-text>
                I have read this release before signing below, and I fully understand the contents, meaning and impact of this release. I understand that I am free to address any
                specific questions regarding this release by submitting those questions to Joe Airone or in writing prior to signing, and I agree that my failure to do so will be
                interpreted as a free and knowledgeable acceptance to the terms of this release.
              </b-card-text>
              <b-card-text>
                Enter your name to electronically sign this document:
                <b-form-group id="input-group-media-signature-1" label-for="input-patient-media-sign" label-cols="2" label-align="right">
                  <template #label>
                    <b>Full Name:</b>
                  </template>
                  <b-form-input
                    id="input-patient-media-sign"
                    maxlength="500"
                    style="max-width: 70% !important"
                    v-model="mediaReleases.patientMediaSignature"
                    type="search"
                    placeholder="Enter full name"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-card-text>

              <b-button class="mt-1 ml-2 justify-content-end" variant="info" @click="() => (showMediaRelease = false)"> Close </b-button></b-card
            >
          </b-col>
        </b-row>
        <b-modal id="confirmExisting" centered header-bg-variant="warning" busy v-model="showExistingConfirmation" title="Patient Already Configured" hide-header-close>
          <p>Patient has already been setup. Update info?</p>
          <br />
          <template #modal-footer>
            <b-button size="sm" variant="danger" @click="cancelView()"> Cancel </b-button>
            <b-button size="sm" variant="outline-warning" @click="updatePatient()" :disabled="stopSave"> Update </b-button>
          </template>
        </b-modal>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import { uploadDocument, postReleases, postContact, postAddress, postQuestionnaire, requestCreatePatient } from "./onboardingSteps";
import vSelect from "vue-select";
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { required, email } from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    WizardButton,
    TabContent
  },
  data() {
    return {
      fullname: null,
      email: null,
      phone: null,
      birthday: null,
      address: {},
      showQualification: false,
      medical: [],
      releaseOptOut: false,
      demographics: [],
      requiredDocuments: [],
      optionalDocuments: [],
      selectedOptional: [],
      releaseUpload: { progress: false, success: false, failure: false },
      qualUpload: { progress: false, success: false, failure: false },
      addressUpload: { progress: false, success: false, failure: false },
      contactUpload: { progress: false, success: false, failure: false },
      iddoc: {
        id: 1,
        type: "ID",
        description: "California State ID",
        bothSides: true,
        file: null,
        progress: false,
        success: false,
        failure: false,
        requiresExpiration: true,
        requiresNumber: true,
        numberText: "Enter License Number",
        dateText: "Enter Expiration Date"
      },
      income: {
        id: 2,
        type: "Income",
        description: "Income Verification",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false,
        notAdded: false,
        breakAfter: true
      },
      diagnosis: { id: 3, type: "Diagnosis", description: "Medical Diagnosis", bothSides: false, file: null, progress: false, success: false, failure: false },
      recco: {
        id: 4,
        type: "Recommendation",
        description: "Doctors Recommendation",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false,
        notAdded: false,
        requiresNumber: true,
        requiresExpiration: false,
        requiresDocumentDate: true,
        requiresName: true,
        numberText: "Enter Physicians License Number",
        nameText: "Enter Physician's Name",
        dateText: "Enter Document Date"
      },
      veteran: {
        id: 5,
        type: "Veteran Id",
        description: "Veteran ID",
        bothSides: true,
        file: null,
        progress: false,
        success: false,
        failure: false,
        requiresExpiration: true,
        requiresNumber: true,
        numberText: "Enter ID Number",
        dateText: "Enter Expiration Date"
      },
      medicalId: {
        id: 6,
        type: "Medical id card",
        description: "Medical ID Card",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false,
        requiresExpiration: true,
        requiresNumber: true,
        numberText: "Enter Medical ID Card Number",
        dateText: "Enter Medical Card Expiration Date"
      },
      sweetleaf: require("@/assets/images/logo/sweetleaf-collective.png"),
      evac: require("@/assets/images/logo/operation-evac.jpeg"),
      jazz: require("@/assets/images/logo/jazz-cabbage.png"),
      cornerstone: require("@/assets/images/logo/cornerstone.png"),
      required,
      email,
      filechanged: 0,
      showPatientAck: false,
      showMedicalRelease: false,
      showMediaRelease: false,
      patientAckSignature: null,
      medicalRecordReleaseSignature: null,
      uploading: 0,
      showExistingConfirmation: false,
      mediaReleases: { general: false, social: false, newsletters: false, magazines: false, website: false, promotional: false },
      operatorName: null,
      operatorEmail: null,
      operatorPhone: null,
      devOverrideValidation: false,
      stopSave: false
    };
  },

  computed: {
    getMinExpiration() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const minDate = new Date(today);
      return minDate;
    },
    getMaxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const minDate = new Date(today);
      return minDate;
    },
    patientMedicalSigned() {
      return this.medicalRecordReleaseSignature && this.medicalRecordReleaseSignature.trim().length > 0;
    },
    patientMediaSigned() {
      return (
        this.mediaReleases.patientMediaSignature &&
        this.mediaReleases.patientMediaSignature.trim().length > 0 &&
        this.mediaReleases.patientMediaInitials &&
        this.mediaReleases.patientMediaInitials.trim().length > 0
      );
    },
    patientAckSigned() {
      return this.patientAckSignature && this.patientAckSignature.trim().length > 0;
    },
    showAgreement() {
      return this.showPatientAck || this.showMedicalRelease || this.showMediaRelease;
    },
    selectedRequired() {
      let toret = [];

      if (this.sweetleafQualified) {
        const toselectF = { ...this.iddoc, documentType: "ID_FRONT", description: "California State ID FRONT", fileType: "CA ID Front" };
        toret.push(toselectF);
        const toselectB = {
          ...this.iddoc,
          documentType: "ID_BACK",
          id: 10,
          description: "California State ID BACK",
          fileType: "CA ID Back",
          requiresExpiration: false,
          requiresNumber: false,
          breakAfter: true
        };
        toret.push(toselectB);
        const toselIncome = { ...this.income, id: 16, documentType: "INCOME", fileType: "Income documentation" };
        toret.push(toselIncome);
        const toselDiag = { ...this.diagnosis, id: 14, documentType: "DIAGNOSIS_MAIN", fileType: "Diagnosis" };
        toret.push(toselDiag);
        const toselRecco = { ...this.recco, id: 15, documentType: "RECOMMENDATION_MAIN", fileType: "Dr Recommendation" };
        toret.push(toselRecco);

        return toret;
      }
      if (this.evacQualified) {
        const toselectF = { ...this.iddoc, documentType: "ID_FRONT", description: "California State ID FRONT", fileType: "CA ID Front" };
        toret.push(toselectF);
        const toselectB = {
          ...this.iddoc,
          documentType: "ID_BACK",
          id: 10,
          description: "California State ID BACK",
          fileType: "CA ID Back",
          requiresExpiration: false,
          requiresNumber: false,
          breakAfter: true
        };
        toret.push(toselectB);
        const toselectVF = { ...this.veteran, documentType: "VETID_FRONT", description: "Veteran ID FRONT", fileType: "Veteran ID Front" };
        toret.push(toselectVF);
        const toselectVB = {
          ...this.veteran,
          documentType: "VETID_BACK",
          id: 11,
          description: "Veteran ID BACK",
          fileType: "Veteran ID Back",
          requiresExpiration: false,
          requiresNumber: false,
          breakAfter: true
        };
        toret.push(toselectVB);

        return toret;
      }
      if (this.jazzCabbageQualified) {
        const toselectF = { ...this.iddoc, documentType: "ID_FRONT", description: "California State ID FRONT", fileType: "CA ID Front" };
        toret.push(toselectF);
        const toselectB = {
          ...this.iddoc,
          documentType: "ID_BACK",
          id: 10,
          description: "California State ID BACK",
          fileType: "CA ID Back",
          requiresExpiration: false,
          requiresNumber: false
        };
        toret.push(toselectB);

        return toret;
      }
      return [];
    },
    sweetleafQualified() {
      if (this.demographics.includes("lowIncome")) {
        this.requiredDocuments.splice(0, this.requiredDocuments.length);
        this.requiredDocuments.push(this.iddoc);
        this.requiredDocuments.push(this.income);
        this.requiredDocuments.push(this.diagnosis);
        this.requiredDocuments.push(this.recco);
        this.optionalDocuments.splice(0, this.optionalDocuments.length);
        this.optionalDocuments.push(this.medicalId);

        this.selectedOptional.splice(0, this.selectedOptional.length);
        const toselDiag = { ...this.medicalId, documentType: "MEDICAL_CARD", fileType: "Medical Card" };
        this.selectedOptional.push(toselDiag);
        return true;
      }
      return false;
    },
    evacQualified() {
      if (this.demographics.includes("lowIncome")) {
        return false;
      }
      if (this.demographics.includes("veteran") && !this.demographics.includes("bipoc")) {
        this.requiredDocuments.splice(0, this.requiredDocuments.length);
        this.requiredDocuments.push(this.iddoc);
        this.requiredDocuments.push(this.veteran);
        this.optionalDocuments.splice(0, this.optionalDocuments.length);
        this.optionalDocuments.push(this.diagnosis);
        this.optionalDocuments.push(this.recco);
        this.optionalDocuments.push(this.medicalId);

        this.selectedOptional.splice(0, this.selectedOptional.length);
        const toselDiag = { ...this.diagnosis, documentType: "DIAGNOSIS_MAIN", fileType: "Diagnosis" };
        this.selectedOptional.push(toselDiag);
        const toselRecco = { ...this.recco, documentType: "RECOMMENDATION_MAIN", fileType: "Dr Recommendation" };
        this.selectedOptional.push(toselRecco);
        const toselMed = { ...this.medicalId, documentType: "MEDICAL_CARD", fileType: "Medical Card" };
        this.selectedOptional.push(toselMed);

        return true;
      }
      return false;
    },
    jazzCabbageQualified() {
      if (this.demographics.includes("lowIncome")) {
        return false;
      }
      if (this.demographics.includes("bipoc")) {
        this.requiredDocuments.splice(0, this.requiredDocuments.length);
        this.requiredDocuments.push(this.iddoc);
        this.optionalDocuments.splice(0, this.optionalDocuments.length);
        this.optionalDocuments.push(this.diagnosis);
        this.optionalDocuments.push(this.recco);
        this.optionalDocuments.push(this.medicalId);

        this.selectedOptional.splice(0, this.selectedOptional.length);
        const toselDiag = { ...this.diagnosis, documentType: "DIAGNOSIS_MAIN", fileType: "Diagnosis" };
        this.selectedOptional.push(toselDiag);
        const toselRecco = { ...this.recco, documentType: "RECOMMENDATION_MAIN", fileType: "Dr Recommendation" };
        this.selectedOptional.push(toselRecco);
        const toselMed = { ...this.medicalId, documentType: "MEDICAL_CARD", fileType: "Medical Card" };
        this.selectedOptional.push(toselMed);

        return true;
      }
      return false;
    },
    cornerstoneQualified() {
      if (this.demographics.includes("lowIncome")) {
        return false;
      }
      if (this.demographics.includes("general")) {
        this.requiredDocuments.splice(0, this.requiredDocuments.length);
        this.requiredDocuments.push(this.iddoc);
        this.optionalDocuments.splice(0, this.optionalDocuments.length);
        this.optionalDocuments.push(this.diagnosis);
        this.optionalDocuments.push(this.recco);
        this.optionalDocuments.push(this.medicalId);

        this.selectedOptional.splice(0, this.selectedOptional.length);
        const toselDiag = { ...this.diagnosis, documentType: "DIAGNOSIS_MAIN", fileType: "Diagnosis" };
        this.selectedOptional.push(toselDiag);
        const toselRecco = { ...this.recco, documentType: "RECOMMENDATION_MAIN", fileType: "Dr Recommendation" };
        this.selectedOptional.push(toselRecco);
        const toselMed = { ...this.medicalId, documentType: "MEDICAL_CARD", fileType: "Medical Card" };
        this.selectedOptional.push(toselMed);

        return true;
      }
      return false;
    },
    programQualifier() {
      if (this.sweetleafQualified) {
        this.showQualification = true;
        return "Qualifies for the Sweetleaf Program";
      }
      if (this.evacQualified) {
        this.showQualification = true;
        return "Qualifies for Operation EVAC";
      }
      if (this.jazzCabbageQualified) {
        this.showQualification = true;
        return "Qualifies for JazzCabbage";
      }
      if (this.cornerstoneQualified) {
        this.showQualification = true;
        return "Qualifies for Cornerstone";
      }
      this.showQualification = false;
      return "";
    }
  },
  methods: {
    cancelView() {
      this.showExistingConfirmation = false;
    },
    async updatePatient() {
      //upload qualifications
      console.log("uploading qualifications now");
      let q = await this.getQualificationsForUpload();
      q.patientUid = this.qualUpload.patientId;
      let statusInfo = this.qualUpload;
      let data = null;
      try {
        const qresponse = await postQuestionnaire(q);
        data = qresponse.data;
        statusInfo.progress = false;
        statusInfo.success = true;
        this.uploading++;
        console.log("uploaded qualifications", data);

        statusInfo = this.addressUpload;
        statusInfo.progress = true;
        let a = await this.getAddressForUpload();
        a.patientUid = this.qualUpload.patientId;
        const aresponse = await postAddress(a);
        data = aresponse.data;
        statusInfo.progress = false;
        statusInfo.success = true;
        this.uploading++;
        console.log("uploaded address", data);

        statusInfo = this.contactUpload;
        statusInfo.progress = true;
        let c = await this.getContactForUpload();
        c.patientUid = this.qualUpload.patientId;
        const cresponse = await postContact(c);
        data = cresponse.data;
        statusInfo.progress = false;
        statusInfo.success = true;
        this.uploading++;
        console.log("uploaded contact", data);

        statusInfo = this.releaseUpload;
        statusInfo.progress = true;
        let r = await this.getReleaseForUpload();
        r.patientUid = this.qualUpload.patientId;
        if (this.releaseOptOut) {
          r.patientOptedOut = true;
        }
        const rresponse = await postReleases(r);
        data = rresponse.data;
        statusInfo.progress = false;
        statusInfo.success = true;
        this.uploading++;
        console.log("uploaded release", data);

        //now do documents:
        for (const d of this.selectedRequired) {
          d.progress = true;
          statusInfo = d;
          let ud = await this.getDocumentForUpload(d);

          const dresponse = await uploadDocument(ud);
          data = dresponse.data;
          console.log("uploaded required document", data);
          statusInfo.progress = false;
          statusInfo.success = true;
          this.uploading++;
        }

        for (const d of this.selectedOptional) {
          d.progress = true;
          statusInfo = d;

          if (!d.file) {
            statusInfo.progress = false;
            statusInfo.notAdded = true;
            this.uploading++;
            continue;
          }
          let ud = await this.getDocumentForUpload(d);
          const dresponse = await uploadDocument(ud);
          data = dresponse.data;
          console.log("uploaded optional document", data);
          statusInfo.progress = false;
          statusInfo.success = true;
          this.uploading++;
        }
      } catch (err) {
        statusInfo.failed = true;
        console.log("error with uploading data", err.response);
      }
    },
    fileSelectedChange(doc, e) {
      doc.file = e;
      this.filechanged++;
    },
    async getDocumentForUpload(docu) {
      let formData = new FormData();
      if (docu.file) {
        formData.append("document", docu.file);
        formData.append("originalFileName", docu.file.name);
      }
      formData.append("documentType", docu.documentType);
      if (docu.documentNumber) {
        formData.append("documentNumber", docu.documentNumber);
      }
      if (docu.requiresExpiration && docu.documentExpiration) {
        formData.append("expiration", docu.documentExpiration);
      }
      if (docu.requiresDocumentDate && docu.documentDate) {
        formData.append("documentDate", docu.documentDate);
      }
      if (docu.physicianName) {
        formData.append("physicianName", docu.physicianName);
      }
      formData.append("patientUid", this.qualUpload.patientId);
      console.log("File upload info", formData);
      return formData;
    },
    getReleaseForUpload() {
      let c = {};
      c.mediaReleaseVersion = "1.0";
      c.mediaReleaseVersion = "1.0";
      c.medicalReleaseVersion = "1.0";
      //c.mediaReleaseUrl = ""
      //c.patientAcknowledgementUrl = ""
      //c.medicalReleaseUrl = ""

      c.mediaReleaseSocialMedia = this.mediaReleases.social;
      c.mediaReleasePublication = this.mediaReleases.general;
      c.mediaReleaseNews = this.mediaReleases.newsletters;
      c.mediaReleaseMagazine = this.mediaReleases.magazines;
      c.mediaReleaseWebsite = this.mediaReleases.website;
      c.mediaReleasePromo = this.mediaReleases.promotional;
      c.mediaReleaseInitial = this.mediaReleases.patientMediaInitials;
      c.mediaReleaseSign = this.mediaReleases.patientMediaSignature;

      c.patientAcknowledgement = true;
      c.acknowledgementSign = this.patientAckSignature;

      c.medicalRelease = true;
      c.medicalReleaseSign = this.medicalRecordReleaseSignature;

      return c;
    },
    getContactForUpload() {
      let c = {};
      c.phone = this.phone;
      c.email = this.email;
      c.birthDate = this.birthday;
      c.fullName = this.fullname;
      return c;
    },
    getAddressForUpload() {
      let a = {};
      a.street = this.address.street;
      a.city = this.address.city;
      a.state = this.address.state;
      a.zip = this.address.zip;
      a.isReliable = this.address.reliable;
      return a;
    },
    getQualificationsForUpload() {
      let q = {};
      q.lowIncome = this.demographics.includes("lowIncome");
      q.bipoc = this.demographics.includes("bipoc");
      q.lgbtq = this.demographics.includes("lgbtq");
      q.veteran = this.demographics.includes("veteran");

      q.medicalRec = this.medical.includes("medicalRec");
      q.hiv = this.medical.includes("hiv");
      q.aids = this.medical.includes("aids");
      q.cancer = this.medical.includes("cancer");
      q.terminal = this.medical.includes("terminal");
      q.mobility = this.medical.includes("mobility");

      return q;
    },
    resetForm() {
      this.$refs.mainForm.reset();

      this.fullname = null;
      this.email = null;
      this.phone = null;
      this.birthday = null;
      this.address = {};
      this.showQualification = false;
      this.medical = [];
      this.releaseOptOut = false;
      this.demographics = [];
      this.requiredDocuments = [];
      this.optionalDocuments = [];
      this.selectedOptional = [];
      this.releaseUpload = { progress: false, success: false, failure: false };
      this.qualUpload = { progress: false, success: false, failure: false };
      this.addressUpload = { progress: false, success: false, failure: false };
      this.contactUpload = { progress: false, success: false, failure: false };
      this.iddoc = {
        id: 1,
        type: "ID",
        description: "California State ID",
        bothSides: true,
        file: null,
        progress: false,
        success: false,
        failure: false,
        requiresExpiration: true,
        requiresNumber: true,
        numberText: "Enter License Number",
        dateText: "Enter Expiration Date"
      };
      this.income = {
        id: 2,
        type: "Income",
        description: "Income Verification",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false,
        notAdded: false,
        breakAfter: true
      };
      this.diagnosis = { id: 3, type: "Diagnosis", description: "Medical Diagnosis", bothSides: false, file: null, progress: false, success: false, failure: false };
      this.recco = {
        id: 4,
        type: "Recommendation",
        description: "Doctors Recommendation",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false,
        notAdded: false,
        requiresNumber: true,
        requiresExpiration: false,
        requiresDocumentDate: true,
        requiresName: true,
        numberText: "Enter Physicians License Number",
        nameText: "Enter Physician's Name",
        dateText: "Enter Document Date"
      };
      this.veteran = {
        id: 5,
        type: "Veteran Id",
        description: "Veteran ID",
        bothSides: true,
        file: null,
        progress: false,
        success: false,
        failure: false,
        requiresExpiration: true,
        requiresNumber: true,
        numberText: "Enter ID Number",
        dateText: "Enter Expiration Date"
      };
      this.medicalId = {
        id: 10,
        type: "Medical Card Id",
        description: "Medical Card ID",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false,
        requiresExpiration: true,
        requiresNumber: true,
        numberText: "Enter Medical Card ID Number",
        dateText: "Enter Medical Card Expiration Date"
      };
      this.showPatientAck = false;
      this.showMedicalRelease = false;
      this.showMediaRelease = false;
      this.patientAckSignature = null;
      this.medicalRecordReleaseSignature = null;
      this.showExistingConfirmation = false;
      this.mediaReleases = { general: false, social: false, newsletters: false, magazines: false, website: false, promotional: false };
      this.stopSave = false;
    },
    async formSubmitted() {
      this.qualUpload.progress = true;
      this.uploading++;
      this.qualUpload.patientId = null;

      try {
        //create customer
        let d = {};
        d.username = this.fullname;
        d.email = this.email;
        d.phone = this.phone;
        d.operatorName = this.operatorName;
        d.operatorEmail = this.operatorEmail;
        d.operatorPhone = this.operatorPhone;
        const { data } = await requestCreatePatient(d);
        console.log("Got response:", data);
        this.qualUpload.patientId = data.PatientId;
        if (data.tooMany && data.count && data.count === 1) {
          console.log("Too many");
          this.showExistingConfirmation = true;
          this.uploading++;
        } else {
          console.log("continuing!");
          this.stopSave = true;
          await this.updatePatient();
        }
      } catch (err) {
        this.qualUpload.patientId = null;
        this.qualUpload.failed = true;
        this.uploading++;
      }
    },
    validateAddress() {
      if (this.devOverrideValidation) {
        return true;
      }
      return new Promise((resolve, reject) => {
        this.$refs.checkAddress.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            //TODO
            //resolve(true);
            reject();
          }
        });
      });
    },
    validateReleases() {
      if (this.devOverrideValidation) {
        return true;
      }
      if (!this.patientAckSignature) {
        return false;
      }
      if (!this.medicalRecordReleaseSignature) {
        return false;
      }
      return true;
    },
    validateDocuments() {
      if (this.devOverrideValidation) {
        return true;
      }
      if (this.selectedRequired.length == 0) {
        return true;
      }
      if (this.selectedRequired.some((f) => !f.file)) {
        //TODO
        //return true;
        return false;
      }
      return true;
    },

    validateQualifications() {
      if (this.devOverrideValidation) {
        return true;
      }
      if (!this.fullname || this.fullname.length === 0) {
        return false;
      }
      if ((!this.email || this.email.length === 0) && (!this.phone || this.phone.length === 0)) {
        return false;
      }
      if (this.evacQualified || this.jazzCabbageQualified || this.sweetleafQualified) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    const usr = store.getters.getAuthUser;
    if (!usr.compassiongroup) {
      this.operatorName = usr.fullname;
      this.operatorEmail = usr.email;
      this.operatorPhone = usr.phone;
    }
    //TODO:
    this.$refs.mainForm.activateAll();
  }
};
</script>
